import React, { Component } from 'react'
import { Redirect, Route } from "react-router-dom";
import AuthService from '../services/AuthService';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = AuthService.getCurrentUser();
        
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        // check if route is restricted by role
        // check that every role is included in currentUser roles
        let checker = (arr, target) => target.every(item => arr.includes(item));
        if (roles && !checker(currentUser.roles,roles)) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)