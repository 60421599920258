import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import AuthService from '../../services/AuthService';
import EstudianteService from '../../services/EstudianteService';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale} from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)


class EstudianteForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentUser: null,
            isAdmin: false,
            currentEstudiante:
            {
                id: undefined,
                cedula: '',
                nombre: '',
                apellido: '',
                email: '',
                genero: '',
                fechaNacimiento: new Date(),
                telefono: '',
                celular: '',
                direccion: '',
                cedulaRep: '',
                nombreRep: '',
                jornada: '',
                estado: '',
                institucion: {id:-1},
                periodoLectivo: '',
                user: undefined,
                observaciones:''
            },
            editMode: false,
            isDisabled: false,
            message: '',
            successful: false,
            selectInstitucion: [],
            institucionId:''
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);       
        this.handleInstitucionChange = this.handleInstitucionChange.bind(this);
        this.handleJornadaChange = this.handleJornadaChange.bind(this);
        this.handleSubmitEstudiante = this.handleSubmitEstudiante.bind(this);
    }

    getEstudianteByUser(){
        EstudianteService.getByUsername(this.state.currentUser.username)
        .then(response => {
            console.log(response.data);
            this.setState({
                currentEstudiante: response.data
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    handleInputChange(event) {
        console.log(event)

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState(prevState => ({
            currentEstudiante:{
                ...prevState.currentEstudiante,
                [name]: value
            }
        })); 
    }

    handleDateChange = (date) => {
        console.log(date);
        this.setState(prevState => ({
            currentEstudiante:{
                ...prevState.currentEstudiante,
                fechaNacimiento: moment(date).format("YYYY-MM-DD")
            }
        }));
    }
    
    handleInstitucionChange(event){
        console.log(event);
        this.setState(prevState => ({
            currentEstudiante:{
                ...prevState.currentEstudiante,
                institucion:{
                    ...prevState.currentEstudiante.institucion,
                    id:event.value
                }
            }
        }));
    }
    
    handleJornadaChange(event){
        console.log(event);
        this.setState(prevState => ({
            currentEstudiante:{
                ...prevState.currentEstudiante,
                jornada:event.value
            }
        }));
    }

    async getOptions(){
        const res = await EstudianteService.getInstituciones();
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.nombre
        }))
        this.setState({selectInstitucion: options})   
    }

    checkEstadoEstudiante(){
        if(this.state.currentEstudiante.id){
            if(this.state.currentEstudiante.estado === "REGISTRADO" || this.state.currentEstudiante.estado === "DEVUELTO"){
                this.setState({
                    editMode:true,
                    isDisabled:false
                });
            }
            else{
                this.setState({
                    editMode:false,
                    isDisabled:true
                });
            }
        }else{
            this.setState({
                editMode:false,
                isDisabled:false,
            });
        }
    }

    handleSubmitEstudiante(e){
        e.preventDefault();
        console.log(this.state.currentEstudiante);
        if(this.state.editMode){
            let estudianteId = this.state.currentEstudiante.id;
            EstudianteService.updateEstudiante(estudianteId,this.state.currentEstudiante)
            .then(
                response => {
                  this.setState({
                    message: "¡Se ha actualizado correctamente!",
                    successful: true
                  });
                },
                error => {
                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
        
                  this.setState({
                    successful: false,
                    message: resMessage
                  });
                }
              );
        }
        else{
            console.log(this.state.currentEstudiante);
            EstudianteService.saveEstudiante(this.state.currentEstudiante)
            .then(
                response => {
                  this.setState({
                    message: "¡Se ha registrado correctamente!",
                    successful: true,
                    editMode:true
                  });
                  this.getEstudianteByUser();
                },
                error => {
                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
        
                  this.setState({
                    successful: false,
                    message: resMessage
                  });
                }
              );
        }
    }

    componentDidMount(){
        //Obtener info del usuario actual
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isAdmin: user.roles.includes("ROLE_ADMIN"),
            });
        }
        
        //Obtener Estudiante
        EstudianteService.getByUsername(user.username)
        .then(response => {
            console.log(response.data);
            this.setState({
                currentEstudiante: response.data
            });
            
            if(this.state.currentEstudiante)
                this.checkEstadoEstudiante();
        })
        .catch(e => {
            console.log(e);
            let newEstudiante = {
                cedula: this.state.currentUser.username,//username=cedula
                nombre: this.state.currentUser.nombre,
                apellido: this.state.currentUser.apellido,
                email: this.state.currentUser.email,
                estado:"REGISTRADO",
                periodoLectivo:{id:5},//TODO: como setear periodo
                user:{id: this.state.currentUser.id}
            };

            this.setState(prevState => ({
                currentEstudiante:{
                    ...prevState.currentEstudiante,
                    cedula: newEstudiante.cedula,
                    nombre: newEstudiante.nombre,
                    apellido: newEstudiante.apellido,
                    email: newEstudiante.email,
                    estado: newEstudiante.estado,
                    periodoLectivo: newEstudiante.periodoLectivo,
                    user: newEstudiante.user
                }
            }));
                
        });

        this.getOptions();
    }

    render() {
        const {
            currentEstudiante,editMode,isDisabled,message,successful
        } = this.state;
        const btnTitleSaveUpdate = editMode ? 'Actualizar' : 'Guardar';

        const jornadaOptions = [
            { value: 'Matutina', label: 'Matutina' },
            { value: 'Vespertina', label: 'Vespertina' },
            { value: 'Nocturna', label: 'Nocturna' }
        ]

        return (
            <div className="page-bg">
                <div className="container">
                    <div className = "row">
                        <div className = "card col-md-10 offset-md-1 offset-md-1">
                            <h3 className=" card-header bg-light text-center">Registrar Estudiante</h3>
                            <div className = "card-body">
                                <form onSubmit={this.handleSubmitEstudiante}>
                                    <div className = "form-group">
                                        <label htmlFor="cedula"> Cédula: </label>
                                        <input placeholder="Cédula de identidad" name="cedula" className="form-control" 
                                            value={currentEstudiante.cedula} onChange={this.handleInputChange} disabled/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="nombre"> Nombres: </label>
                                        <input placeholder="Nombres" name="nombre" className="form-control" 
                                            value={currentEstudiante.nombre} onChange={this.handleInputChange} disabled/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="apellido"> Apellidos: </label>
                                        <input placeholder="Apellidos" name="apellido" className="form-control" 
                                            value={currentEstudiante.apellido} onChange={this.handleInputChange} disabled/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="email" > Email: </label>
                                        <input placeholder="Correo electrónico" name="email" className="form-control" 
                                            value={currentEstudiante.email} onChange={this.handleInputChange} disabled/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="genero"> Género: </label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="genero" id="Masculino" value="Masculino" checked={currentEstudiante.genero === "Masculino"} onChange={this.handleInputChange} disabled={isDisabled}/>
                                            <label className="form-check-label" htmlFor="Masculino">
                                                Masculino
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="genero" id="Femenino" value="Femenino" checked={currentEstudiante.genero === "Femenino"} onChange={this.handleInputChange} disabled={isDisabled}/>
                                            <label className="form-check-label" htmlFor="Femenino">
                                                Femenino
                                            </label>
                                        </div>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="fechaNacimiento"> Fecha de Nacimiento: </label><br/>
                                        <DatePicker locale="es" className="form-control" dateFormat="dd/MM/yyyy" selected={moment(currentEstudiante.fechaNacimiento).toDate()} onChange={this.handleDateChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="telefono"> Teléfono: </label>
                                        <input placeholder="Teléfono" name="telefono" className="form-control" 
                                            value={currentEstudiante.telefono} onChange={this.handleInputChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="celular"> Celular: </label>
                                        <input placeholder="Celular" name="celular" className="form-control" 
                                            value={currentEstudiante.celular} onChange={this.handleInputChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="direccion"> Dirección: </label>
                                        <input placeholder="Dirección" name="direccion" className="form-control" 
                                            value={currentEstudiante.direccion} onChange={this.handleInputChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="cedulaRep"> Cédula Representante: </label>
                                        <input placeholder="Cédula del Representante" name="cedulaRep" className="form-control" 
                                            value={currentEstudiante.cedulaRep} onChange={this.handleInputChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label htmlFor="nombreRep"> Nombre Representante: </label>
                                        <input placeholder="Nombre del Representante" name="nombreRep" className="form-control" 
                                            value={currentEstudiante.nombreRep} onChange={this.handleInputChange} disabled={isDisabled}/>
                                    </div>
                                    <div className = "form-group">
                                        <label> Institución: </label>
                                        <Select
                                            value={this.state.selectInstitucion.filter(obj => obj.value === currentEstudiante.institucion.id)} // set selected value 
                                            options={this.state.selectInstitucion} onChange={this.handleInstitucionChange} isDisabled={isDisabled} />
                                    </div>
                                    <div className = "form-group">
                                        <label> Jornada: </label>
                                        <Select
                                            value={jornadaOptions.filter(obj => obj.value === currentEstudiante.jornada)} // set selected value 
                                            options={jornadaOptions} onChange={this.handleJornadaChange} isDisabled={isDisabled} />
                                    </div>
                                    {!isDisabled &&(
                                        <div>
                                            <button className="btn btn-primary" >{btnTitleSaveUpdate}</button>
                                        </div>
                                    )}
                                    {message && (
                                        <div className="form-group pt-2">
                                            <div
                                            className={
                                                successful
                                                ? "alert alert-success"
                                                : "alert alert-danger"
                                            }
                                            role="alert"
                                            >
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



}

export default EstudianteForm
