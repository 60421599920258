import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/AuthService";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        El email no es válido
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vnombre = value => {
  if (value.length < 3 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo nombre debe tener una longitud entre 3 y 20 caractéres
      </div>
    );
  }
};

const vapellido = value => {
  if (value.length < 3 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo apellido debe tener una longitud entre 3 y 20 caractéres
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        La contraseña debe tener entre 6 a 40 caracteres.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeApellido = this.onChangeApellido.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    //this.validateByCedula = this.validateByCedula.bind(this);

    this.state = {
      username: "",
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      successful: false,
      message: "",
      isDisabled: true
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value
    });
  }

  onChangeApellido(e) {
    this.setState({
      apellido: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  async validateByCedula(cedula){
    try{
      const res = await AuthService.getByCedula(cedula);
      console.log(res);
      this.setState({
        nombre: res.data.nombre,
        apellido: res.data.apellido,
        message: '',
        isDisabled:false
      });
      
    }catch(err){
      console.log(err);
      this.setState({
        nombre: '',
        apellido: '',
        email: '',
        password: '',
        successful: false,
        message: "No se ha encontrado la cédula ingresada",
        isDisabled:true
      });
    }
  }


  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.nombre,
        this.state.apellido,
        this.state.email,
        this.state.password
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    const {
        isDisabled
    } = this.state;

    return (
      /*<div className="row page-bg"> 
        <div className="col-md-12">
          <div className="card card-container">
          <h3 className="text-center">Atención:</h3>
          <p className="card-text text-center">El período de inscripciones de los bachilleres de la <b>PROMOCIÓN 2022 – 2023</b> ha concluido.</p>
          </div>
        </div>
      </div>*/

      <div className="row page-bg"> 
        <div className="col-md-12">
          <div className="card card-container">
          <h3 className="text-center">Registro de Usuario</h3>

            <Form
              onSubmit={this.handleRegister}
              ref={c => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="username">Usuario (Cédula de identidad)</label>
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                      <div className="input-group-append">
                      <button className="btn btn-secondary" 
                        type="button"
                        onClick={()=>{this.validateByCedula(this.state.username)}}
                      >
                        <FontAwesomeIcon icon="search"/>
                      </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nombre">Nombres</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={this.state.nombre}
                      onChange={this.onChangeNombre}
                      validations={[required, vnombre]}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="apellido">Apellidos</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="apellido"
                      value={this.state.apellido}
                      onChange={this.onChangeApellido}
                      validations={[required, vapellido]}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      validations={[required, email]}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="form-group">
                    <button className="btn btn-primary btn-block" disabled={isDisabled}>Registrar</button>
                  </div>
                </div>
              )}

              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
      
    );
  }
}