import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import './App.css';
import EstudianteForm from './components/Estudiante/EstudianteForm';
import EstudianteList from './components/Estudiante/EstudianteList';
import FileUpload from './components/Estudiante/FileUpload';
import FooterComponent from './components/FooterComponent';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import Login from './components/Login';
import { PrivateRoute } from './components/PrivateRoute';
import Profile from './components/Profile';
import Register from './components/Register';
import Rendicion from "./components/Rendicion/Rendicion";
import Rendicion2021 from "./components/Rendicion/Rendicion2021";
import TabletEntrega from './components/Tablet/TabletEntrega';
import TabletIngreso from "./components/Tablet/TabletIngreso";
import logo_bachiller from './logo-bachiller-digital.png';
import AuthService from './services/AuthService';
import ResetPassword from "./components/ResetPassword";


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
      isNavCollapsed: true,
      location: {
        pathname: window.location.hash.slice(1) || '/', // the hash value of the current page
        state: {}   //Saved state
    }

    };

    this.logOut = this.logOut.bind(this);
  }

  handleNavCollapse = () => {this.setState({isNavCollapsed:!this.state.isNavCollapsed});}

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        isAdmin: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
    window.location.reload();
    //this.props.history.push("/login");
  }

  render() {
    const { currentUser, isAdmin, isNavCollapsed, location } = this.state;

    return (
      <div className="app-container">
        {(location.pathname !== '/rendicion-de-cuentas' && location.pathname !== '/rendicion-de-cuentas-2021') && (
        <nav className="navbar navbar-expand-md navbar-light bg-white">
          <Link to={"/"} className="navbar-brand">
            <img src={logo_bachiller} width="136" height="80" alt="Bachiller Digital Daule"/>
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#coll" aria-controls="coll" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={this.handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="coll">
            <div className="navbar-nav mr-auto" >
              <li className="nav-item">
                <Link to={"/home"} className="nav-link">
                  <FontAwesomeIcon icon="home"/> Inicio
                </Link>
              </li>

              {currentUser && !isAdmin && (
                <li className="nav-item">
                  <Link to={"/inscripcion"} className="nav-link">
                    Inscripción
                  </Link>
                </li>
              )}

              {currentUser && !isAdmin && (
                <li className="nav-item">
                  <Link to={"/upload"} className="nav-link">
                    Adjuntar Documentación
                  </Link>
                </li>
              )}
              
              {isAdmin && (
                <li className="nav-item">
                  <Link to={"/reporte"} className="nav-link">
                    Reporte
                  </Link>
                </li>
              )}

              {isAdmin && (
                <li className="nav-item">
                  <Link to={"/acta"} className="nav-link">
                    Acta de Entrega
                  </Link>
                </li>
              )}

              {isAdmin && (
                <li className="nav-item">
                  <Link to={"/tablet-ingreso"} className="nav-link">
                    Ingreso de Tablets
                  </Link>
                </li>
              )}

          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.nombre.toUpperCase() + " " + currentUser.apellido.toUpperCase()}
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/login"} className="nav-link" onClick={this.logOut}>
                  Cerrar sesión
                </Link>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Iniciar sesión
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Registrarse
                </Link>
              </li>
            </div>
          )}
          </div>
        </nav>
        )}
      <div className = "page-content">
        <div className="container-fluid mt-3">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} /> 
            <Route exact path="/forgot-password" component={ForgotPassword} /> 
            <Route path="/reset-password/:token" component={ResetPassword} /> 
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/inscripcion" component={EstudianteForm} />
            <PrivateRoute exact path="/upload" component={FileUpload} />
            <PrivateRoute exact path="/reporte" roles={["ROLE_ADMIN"]} component={EstudianteList} />
            <PrivateRoute exact path="/acta" roles={["ROLE_ADMIN"]} component={TabletEntrega} />
            <PrivateRoute exact path="/tablet-ingreso" roles={["ROLE_ADMIN"]} component={TabletIngreso} />
            <Route exact path="/rendicion-de-cuentas" component={Rendicion} />
            <Route exact path="/rendicion-de-cuentas-2021" component={Rendicion2021} />
          </Switch>
        </div>
      </div>

      <FooterComponent/>
    </div>
    );
  }


}

export default App;
