import React, { Component } from "react";
import bannerRC from '../../images/web-rc-2021.png';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import MailService from "../../services/MailService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNumeric } from "validator";


const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const numeric = value => {
  if (!isNumeric(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Ingrese solo numeros 
      </div>
    );
  }
};

export default class Rendicion2021 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      nombre: "",
      cedula: "",
      sector: "",
      sugerencia:"",
      message:"",
      successful:false
    };

    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeCedula = this.onChangeCedula.bind(this);
    this.onChangeSector = this.onChangeSector.bind(this);
    this.onChangeSugerencia = this.onChangeSugerencia.bind(this);
    this.handleEnviarSugerencia = this.handleEnviarSugerencia.bind(this);
  }

  componentDidMount() {
        this.setState({
          content: "FALTA CONTENIDO PAGINA PRINCIPAL"
        });
  }

  clearForm(){
    this.setState({
      nombre: "",
      cedula:"",
      sector:"",
      sugerencia:""
    });
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value
    });
  }
  onChangeCedula(e) {
    this.setState({cedula: e.target.value.replace(/\D/g, '')})
  }
  onChangeSector(e) {
    this.setState({
      sector: e.target.value
    });
  }
  onChangeSugerencia(e) {
    this.setState({
      sugerencia: e.target.value
    });
  }

  handleEnviarSugerencia(e){
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      MailService.enviarCorreoRendicion(
        this.state.nombre,
        this.state.cedula,
        this.state.sector,
        this.state.sugerencia
      )
      .then((response) =>{
          console.log(response.data.message);
          this.setState({
            message:"Se ha enviado la sugerencia",
            successful: true
          });
          this.clearForm();
      })
      .catch((error)=>{
          console.log(error);
          this.setState({
            message:"Ha ocurrido un error al enviar la sugerencia",
            successful: false
          });
      });
    }
  }

  render() {
    return (
      <div>
        
        <div className="container-fluid px-0">
          <div className="row no-gutters">
              <div className="col-md-12 text-center">
                <img src={bannerRC} className="img-fluid mx-auto d-block" alt="header"/>
              </div>
          </div>
        </div>      

        <div className="container-fluid my-5">
          <div className="row section-title-2">
            <div className="col-md-12">
                <h4>VIDEO DE LA RENDICIÓN DE CUENTAS 2021</h4>
            </div>
          </div>
            <div className="row mt-5">
                <div className="offset-md-3 col-md-6">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/AOyy-IDtavU" title="Rendición de cuentas 2020" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid my-5">
          <div className="row section-title-2">
            <div className="col-md-12">
              <h4>CONTENIDO RENDICIÓN DE CUENTAS 2021</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
              <p className="text-center">
                <a href="https://www.daule.gob.ec/documents/20124/429138/rendicion_cuentas_2021.pdf" target="_blank" rel="noopener noreferrer">Informe de Rendición de Cuentas 2021</a>
              </p>
              <hr/>
              <p className="text-center">
                <a href="https://www.daule.gob.ec/documents/20124/429138/formulario-GAD+Daule+2021+.pdf" target="_blank" rel="noopener noreferrer">Formulario de Rendición de Cuentas 2021</a>
              </p>
              <hr/>
            </div>
          </div>
       </div>
{/*
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Click me!
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>Hello! I'm the body</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Click me!
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>Hello! I'm another body</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
*/}     
        <div className="container-fluid">
          <div className="row section-title-2">
            <div className="col-md-12">
                <h4>SUGERENCIAS</h4>
            </div>
          </div>
          <div className="row no-gutters py-4">
              <div className="offset-md-2 col-md-8">

                <Form
                onSubmit={this.handleEnviarSugerencia}
                ref={c => {
                    this.form = c;
                }}
                >
                {!this.state.successful && (
                    <div>
                        <div className="form-group">
                            <label htmlFor="nombre">Nombres y Apellidos</label>
                            <Input
                            type="text"
                            placeholder="Nombres y Apellidos"
                            className="form-control"
                            name="nombre"
                            value={this.state.nombre}
                            onChange={this.onChangeNombre}
                            validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="cedula">N° Cédula</label>
                            <input
                            type="text"
                            placeholder="Número de cédula"
                            className="form-control"
                            name="cedula"
                            value={this.state.cedula}
                            onChange={this.onChangeCedula}
                            maxLength={10}
                            required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="sector">Sector</label>
                            <Input
                            type="text"
                            placeholder="Sector/Parroquia"
                            className="form-control"
                            name="sector"
                            value={this.state.sector}
                            onChange={this.onChangeSector}
                            validations={[required]}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="sugerencia">Sugerencia ciudadana</label>
                            <Textarea 
                              placeholder="Escriba aquí su sugerencia..." 
                              name="sugerencia" 
                              className="form-control" 
                              rows="3" 
                              value={this.state.sugerencia} 
                              onChange={this.onChangeSugerencia} 
                              validations={[required]}
                              />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block">Enviar <FontAwesomeIcon icon="share"/></button>
                        </div>
                    </div>
                )}

                {this.state.message && (
                    <div className="form-group">
                    <div
                        className={
                        this.state.successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                    >
                        {this.state.message}
                    </div>
                    </div>
                )}
                <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                    this.checkBtn = c;
                    }}
                />
                </Form>              
              </div>
          </div>
                  </div> 
      </div>
    );
  }
}