import React, { Component } from "react";
import banner from '../images/header-banner.png';
import section_1 from '../images/section-1.png';
import section_3 from '../images/BACHILLER-DIGITAL-01.png';
import item_1 from '../images/item-1.png';
import item_2 from '../images/item-2.png';
import item_3 from '../images/item-3.png';
import item_4 from '../images/item-4.png';
import item_5 from '../images/item-5.png';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
        this.setState({
          content: "FALTA CONTENIDO PAGINA PRINCIPAL"
        });
  }

  render() {
    return (
      <div>
        
        <div className="container-fluid px-0">
          <div className="row no-gutters">
              <div className="col-12">
                <img src={banner} className="img-fluid mx-auto d-block" alt="header"/>
              </div>
          </div>
        </div>      

        {/*<div className="jumbotron jumbotron-fluid header-banner">
          <div className="container text-center">          
            <br/><br/><Link className="btn btn-primary btn-lg" role="button" to={"/register"}>Regístrate</Link>
          </div>
        </div>*/}

        <div className="container-fluid section-1">
          <div className="row no-gutters">
            <div className="col-md-6 align-self-center"> 
              <div className="card-body">
                <h3 className="card-title">BACHILLER DIGITAL DAULE</h3>
                <p className="card-text text-justify">Es un proyecto de inversión municipal dirigido a estudiantes que hayan finalizado el tercer año de bachillerato de establecimientos educativos públicos y privados situados en el cantón Daule y cuyo domicilio civil se encuentre en el mismo cantón.</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={section_1} className="img-fluid pt-5" alt="section-1"/>
            </div>
          </div>
        </div>

        <div className="container-fluid section-2 py-3">
          <div className="bg-white">
            <div className="row no-gutters">
            <div className="col-xs-12 p-3">
              <p className="text-justify"><strong>LOS DOCUMENTOS QUE DEBEN PRESENTAR LOS BACHILLERES BENEFICIARIOS</strong> según lo establece el Artículo 5, de la Gaceta Oficial que reglamenta LA IMPLEMENTACIÓN DEL PROYECTO MUNICIPAL DE INVERSIÓN PÚBLICA DENOMINADO <strong>"BACHILLER DIGITAL DAULE"</strong> son los siguientes:</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="card-group">
              <div className="card mt-0">
                <img src={item_1} className="card-img-top img-item" alt="item-1"/>
                <div className="card-body">
                  <p className="card-text text-center"><strong>Copia a color de la cédula de identidad</strong> del bachiller y su representante en caso de ser menor de edad.</p>
                </div>
              </div>

              <div className="card mt-0">
              <img src={item_2} className="card-img-top img-item" alt="item-2"/>
                <div className="card-body">
                  <p className="card-text text-center"><strong>Copia a color del certificado de votación,</strong> cuando corresponda del bachiller y su representante.</p>
                </div>
              </div>

              <div className="card mt-0">
                <img src={item_3} className="card-img-top img-item" alt="item-3"/>
                <div className="card-body">
                  <p className="card-text text-center"><strong>Copia certificada por notario público del acta de grado,</strong> correspondiente al año de la convocatoria.</p>
                </div>
              </div>

              <div className="card mt-0">
              <img src={item_4} className="card-img-top img-item" alt="item-4"/>
                <div className="card-body">
                  <p className="card-text text-center"><strong>Certificado de inscripción</strong> en el proyecto municipal de inversión pública denominado: <strong>"Bachiller Digital Daule"</strong> debidamente suscrito.</p>
                </div>
              </div>

              <div className="card mt-0">
              <img src={item_5} className="card-img-top img-item" alt="item-5"/>
                <div className="card-body">
                  <p className="card-text text-center"><strong>Copia de la planilla de servicios básicos</strong> en el cual se establezca el domicilio civil del beneficiario.</p>
                </div>
                
              </div>
            </div>
          </div></div>
        </div>

        <div className="container-fluid section-3 px-0">
          <div className="row section-title">
          <div className="col-12">
            <h2>COMO INSCRIBIRSE AL PROGRAMA</h2>
            <h3>BACHILLER DIGITAL DAULE PERIODO 2023-2024</h3>
          </div>
          </div>
          <div className="row no-gutters">
              <div className="col-12">
                <img src={section_3} className="img-fluid mx-auto d-block" alt="section-3"/>
              </div>
          </div>
        </div>
      </div>
    );
  }
}