import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    //if (authenticationService.currentUserValue) { 
    //  this.props.history.push('/');
    //}

    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsernameAlt(e) {
    const regexp = /^[0-9\b]+$/;
    if (e.target.value === '' || regexp.test(e.target.value)) {
      this.setState({ username: e.target.value })
    }
  }

  onChangeUsername(e) {  
    this.setState({ username: e.target.value });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/profile");
          window.location.reload();
        },
        error => {
          let resMessage = "";
          if (!error?.response){
            resMessage = "Error: No hay respuesta del servidor";
          } else if (error.response?.status === 401){
            resMessage = "Error: Credenciales incorrectas";
          } else{
            resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          }
          //const resMessage = 
            //(error.response &&
              //error.response.data &&
              //error.response.data.message) ||
            //error.message ||
            //error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="row page-bg">
        <div className="col-md-12">
          <div className="card card-container">

            <h3 className="text-center">Inicio de Sesión</h3>
            {/*<img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />*/}

            <Form
              onSubmit={this.handleLogin}
              ref={c => {
                this.form = c;
              }}
            >
              <div className="form-group">
                <label htmlFor="username">Usuario (Cédula de identidad)</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Inicia Sesión</span>
                </button>
              </div>
              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
             <p className="text-center">¿No tienes una cuenta? - <Link to={"/register"}>Regístrate</Link></p>
              <p className="text-center"><Link to={"/forgot-password"}>¿Olvidaste tu contraseña?</Link></p>

              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
        </div>
    );
  }
}