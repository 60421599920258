import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import EstudianteService from "../../services/EstudianteService";
import FileUploadService from "../../services/FileUploadService";
import { Modal, Button, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import MailService from "../../services/MailService";
import Pagination from "@material-ui/lab/Pagination";
import Select from 'react-select';
import fileDownload from 'js-file-download';

class EstudianteList extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentEstudiante:
            {
                id: undefined,
                cedula: '',
                nombre: '',
                apellido: '',
                email: '',
                genero: '',
                fechanacimiento: '',
                telefono: '',
                celular: '',
                direccion: '',
                cedulaRep: '',
                nombreRep: '',
                jornada: '',
                estado: '',
                observaciones: '',
                institucion: {id:-1},
                periodoLectivo: '',
                user: undefined
            },

            editMode: false,
            isDisabled: true,
            message: '',
            successful: false,

            modalAprobar: false,
            modalVer: false,
            modalCorreo: false,
            modalDocumentos: false,
            modalDevolucion: false,
            modalNoAplica: false,
            modalCambiarEmail: false,

            mensajeCorreo:'',
            mensajeDevolucion:'',
            mensajeNoAplica:'',
            nuevoEmail:'',
            fileInfo: undefined,
            loading:true,
            estudiantes: [],
            selectInstitucion: [],

            searchInput:'',
            searchPeriodoLectivo:5,
            searchInstitucion:undefined,
            searchEstado:undefined,
            
            page: 1,
            count: 0,
            pageSize: 50,
            totalElements:0
        }

        this.pageSizes = [10, 20, 30, 40, 50, 100];
        this.periodoLectivoOptions = [
            { value: 1, label: 'PERIODO LECTIVO 2019-2020' },
            { value: 2, label: 'PERIODO LECTIVO 2020-2021' },
            { value: 3, label: 'PERIODO LECTIVO 2021-2022' },
            { value: 4, label: 'PERIODO LECTIVO 2022-2023' },
            { value: 5, label: 'PERIODO LECTIVO 2023-2024' }
        ]
        this.estadoOptions = [
            { value: 'REGISTRADO', label: 'REGISTRADO' },
            { value: 'SOLICITADO', label: 'SOLICITADO' },
            { value: 'APROBADO', label: 'APROBADO' },
            { value: 'FINALIZADO', label: 'FINALIZADO' },
            { value: 'DEVUELTO', label: 'DEVUELTO' },
            { value: 'NO_APLICA', label: 'NO_APLICA' }
        ]

        this.updateEstado = this.updateEstado.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);       
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.handlePeriodoLectivoChange = this.handlePeriodoLectivoChange.bind(this);
        this.handleInstitucionChange = this.handleInstitucionChange.bind(this);
        this.handleEstadoChange = this.handleEstadoChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleMensajeDevolucionChange = this.handleMensajeDevolucionChange.bind(this);
        this.handleMensajeCorreoChange = this.handleMensajeCorreoChange.bind(this);
        this.handleMensajeNoAplicaChange = this.handleMensajeNoAplicaChange.bind(this);
        this.handleNuevoEmailChange = this.handleNuevoEmailChange.bind(this);


        this.getEstudiantes = this.getEstudiantes.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState(prevState => ({
            currentEstudiante:{
                ...prevState.currentEstudiante,
                [name]: value
            }
        })); 
    }

    handleSearchInputChange(e) {
        const searchInput = e.target.value;
    
        this.setState({
            searchInput: searchInput,
        });
    }

    handlePeriodoLectivoChange(event){
        if(!event){
            this.setState({
                searchPeriodoLectivo:'',
            });
        }else{
        this.setState({
            searchPeriodoLectivo:event.value,
            });
        }
    }

    handleInstitucionChange(event){
        if(!event){
            this.setState({
                searchInstitucion:'',
            });
        }else{
            this.setState({
                searchInstitucion:event.value,
            });
        }
    }

    handleEstadoChange(event){
        if(!event){
            this.setState({
                searchEstado:'',
            });
        }else{
        this.setState({
            searchEstado:event.value,
            });
        }
    }

    handlePageChange(event, value) {
        this.setState(
          {
            page: value,
          },
          () => {
            this.getEstudiantes();
          }
        );
    }
    
    handlePageSizeChange(event) {
        this.setState(
          {
            pageSize: event.target.value,
            page: 1
          },
          () => {
            this.getEstudiantes();
          }
        );
    }

    handleMensajeCorreoChange(e) {
        const mensaje = e.target.value;
    
        this.setState({
            mensajeCorreo: mensaje
        });
    }

    handleMensajeDevolucionChange(e) {
        const mensaje = e.target.value;
    
        this.setState({
            mensajeDevolucion: mensaje
        });
    }

    handleMensajeNoAplicaChange(e) {
        const mensaje = e.target.value;
    
        this.setState({
            mensajeNoAplica: mensaje
        });
    }

    handleNuevoEmailChange(e) {
        const nEmail = e.target.value;
    
        this.setState({
            nuevoEmail: nEmail
        });
    }

    async getInstituciones(){
        const res = await EstudianteService.getInstituciones();
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.nombre
        }))
        this.setState({selectInstitucion: options})   
    }

    getRequestParams(searchInput, periodoLectivo, institucion, estado, page, pageSize) {
        let params = {};
    
        if (searchInput) {
          params["search"] = searchInput;
        }

        if (periodoLectivo) {
            params["periodoLectivo"] = periodoLectivo;
        }

        if (institucion) {
        params["institucion"] = institucion;
        }

        if (estado) {
            params["estado"] = estado;
        }
    
        if (page) {
          params["page"] = page - 1;
        }
    
        if (pageSize) {
          params["size"] = pageSize;
        }
    
        return params;
    }
    

    /*async getEstudiantes(){
        try{
            const res = await EstudianteService.getAll()  
            this.setState({ 
                estudiantes: res.data,
                loading:false
            });
        }catch(err){
            console.log(err);
        }
    }*/

    async getEstudiantes(){
        const {searchInput, searchPeriodoLectivo,searchInstitucion,searchEstado,page,pageSize} = this.state;
        const params = this.getRequestParams(searchInput, searchPeriodoLectivo,searchInstitucion,searchEstado, page, pageSize);

        try{
            const res = await EstudianteService.getAllFilter(params);  
            const page = res.data;
            this.setState({ 
                estudiantes: page.content,
                count:page.totalPages,
                totalElements:page.totalElements,
                loading:false
            });
            
        }catch(err){
            console.log(err);
        }
    }

    download(){
        const {searchInput, searchPeriodoLectivo,searchInstitucion,searchEstado,page,pageSize} = this.state;
        const params = this.getRequestParams(searchInput, searchPeriodoLectivo,searchInstitucion,searchEstado, page, pageSize);

        EstudianteService.exportToExcel(params).then((res) => {
            fileDownload(res.data, "ESTUDIANTES.xlsx");
        });
    }

    selectEstudiante = (estudiante) =>{
        this.setState({currentEstudiante: estudiante});
    }

    handleAprobar = () =>{
        this.updateEstado(this.state.currentEstudiante,"APROBADO");
        this.handleModalAprobar();
    }

    handleDevolver(estudiante,mensaje){
        
        MailService.enviarCorreoDevolucion(estudiante,mensaje)
        .then((response) =>{
            console.log(response.data.message);
            this.updateEstado(estudiante,"DEVUELTO");
            this.handleModalDevolucion();
        })
        .catch((error)=>{
            console.log(error);
        });
    }

    handleNoAplica(estudiante,mensaje){
        let updateEstudiante = {
            ...estudiante,
            estado: "NO_APLICA",
            observaciones: mensaje
        }

        EstudianteService.updateEstudiante(estudiante.id,updateEstudiante)
        .then(
            response => {
                console.log(response.data);
                this.getEstudiantes();
                this.handleModalNoAplica();
            },
            error => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                console.log(resMessage);
            }
        );
    }

    handleUpdateEmailEstudiante(id, email){
        EstudianteService.updateEmailEstudiante(id,email)
        .then(
            response => {
                console.log(response.data);
                this.setState({
                    message: '',
                    successful:true
                });

                this.setState(prevState => ({
                    currentEstudiante: {
                        ...prevState.currentEstudiante,
                        email:email
                    }
                }));

                this.getEstudiantes();
                this.handleModalCambiarEmail();
            },
            error => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                console.log(resMessage);

                this.setState({
                    message: resMessage,
                    successful:false
                });
            }
        );
    }

    updateEstado(estudiante, estado){
        let updateEstudiante = {
            ...estudiante,
            estado: estado
        }
        
        EstudianteService.updateEstudiante(estudiante.id,updateEstudiante)
        .then(
            response => {
                console.log(response.data);
                this.setState(prevState => ({
                    estudiantes: prevState.estudiantes.map(
                      est => est.id === estudiante.id? { ...est, estado: estado }: est
                    )
                  }));
            },
            error => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                console.log(resMessage);
            }
        );
    }

    handleEnviarCorreo(estudiante, mensaje){
        MailService.enviarCorreoEstudiante(estudiante,mensaje)
        .then((response) =>{
            console.log(response.data.message);
        })
        .catch((error)=>{
            console.log(error);
        });
        this.handleModalCorreo();
    }

    handleModalAprobar = () =>{
        this.setState({modalAprobar: !this.state.modalAprobar});
    }

    handleModalVer = () =>{
        this.setState({
            modalVer: !this.state.modalVer,
            editMode: false,
            isDisabled: true
        });
    }

    /* BOTON EDITAR*/
    handleEditar(estudiante) {

        if(this.state.editMode){
            EstudianteService.updateEstudiante(estudiante.id,estudiante)
            .then(
                response => {
                    console.log(response.data);
                    this.getEstudiantes();
                },
                error => {
                    const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage);
                }
            );
        }
        this.setState({
            editMode: !this.state.editMode,
            isDisabled: !this.state.isDisabled
        });
    }

    handleModalCorreo = () =>{
        this.setState({
            modalCorreo: !this.state.modalCorreo,
            mensajeCorreo:""
        });
    }
    
    handleModalDocumentos= () =>{
        this.setState({modalDocumentos: !this.state.modalDocumentos});
    }

    handleModalDevolucion= () =>{
        this.setState({
            modalDevolucion: !this.state.modalDevolucion,
            mensajeDevolucion:""
        });
    }

    handleModalNoAplica = () =>{
        this.setState({
            modalNoAplica: !this.state.modalNoAplica,
            mensajeNoAplica:""
        });
    }

    handleModalCambiarEmail = () =>{
        this.setState({
            modalCambiarEmail: !this.state.modalCambiarEmail,
            nuevoEmail:"",
            message:""
        });
    }

    getDocumentoEstudiante(estudianteId){
        FileUploadService.getFileByEstudiante(estudianteId)
      .then((response) => {
        this.setState({
          fileInfo: response.data
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({
            fileInfo: undefined
          });
      });
    }

    getActaUrl(estudianteId){
        const  ActaURL= "https://bachillerdigital.daule.gob.ec/api/acta/pdf?id="+estudianteId;
        return ActaURL;
    }

    getActaMayorUrl(estudianteId){
        const  ActaMayorURL= "https://bachillerdigital.daule.gob.ec/api/acta/mayor?id="+estudianteId;
        return ActaMayorURL;
    }

    componentDidMount(){
        this.getInstituciones();
        this.getEstudiantes();
    }

    render() {
        const {currentEstudiante,estudiantes,modalAprobar,modalVer,modalCorreo,modalDocumentos,modalDevolucion,modalNoAplica,modalCambiarEmail,mensajeCorreo,mensajeDevolucion,mensajeNoAplica,nuevoEmail,fileInfo,loading,selectInstitucion,searchInput,searchPeriodoLectivo,searchInstitucion,searchEstado,page,count,pageSize,totalElements,editMode,isDisabled,message,successful} = this.state;
        const  FichaURL= currentEstudiante ? ("https://bachillerdigital.daule.gob.ec/api/estudiante/ficha?id="+currentEstudiante.id) : '#';
        const btnTitleEditSave = editMode ? 'Guardar' : 'Editar';
        const btnIconEditSave = editMode ? 'save' : 'edit';

        return (
            <div>
                <div className="list row">
                    <div className="col-md-5">
                        <div className="input-group mb-3">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar..."
                            value={searchInput}
                            onChange={this.handleSearchInputChange}
                            />
                            <div className="input-group-append">
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={this.getEstudiantes}
                            >
                                Buscar
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <Select
                            value={this.periodoLectivoOptions.filter(obj => obj.value === searchPeriodoLectivo)} // set selected value 
                            options={this.periodoLectivoOptions} onChange={this.handlePeriodoLectivoChange} placeholder={'Período Lectivo'} isClearable={true}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            value={selectInstitucion.filter(obj => obj.value === searchInstitucion)} // set selected value 
                            options={selectInstitucion} onChange={this.handleInstitucionChange} placeholder={'Institucion'} isClearable={true}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            value={this.estadoOptions.filter(obj => obj.value === searchEstado)} // set selected value 
                            options={this.estadoOptions} onChange={this.handleEstadoChange} placeholder={'Estado'} isClearable={true}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    {"Items por página: "}
                    <select onChange={this.handlePageSizeChange} value={pageSize}>
                    {this.pageSizes.map((size) => (
                        <option key={size} value={size}>
                        {size}
                        </option>
                    ))}
                    </select>

                    <Pagination
                    className="my-3"
                    count={count}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handlePageChange}
                    />
                    <p>Total items: {totalElements}</p>
                </div>

                 <h2 className="text-center">Listado de Estudiantes Registrados</h2>

                 {/* BOTON EXPORTAR */}
                <Button variant="outline-success" onClick={()=>{this.download()}}>Exportar <FontAwesomeIcon icon="table"/> </Button>

                 <hr className="my-4"/>

                 {loading? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                 ):(
                 <div className = "row">
                    <div className="table-responsive">
                        <table className = "table table-striped table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Cédula</th>
                                    <th scope="col">Apellido</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Institución</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Ver</th>
                                    <th scope="col">Docs</th>
                                    <th scope="col">Aprobar</th>
                                    <th scope="col">Devolver</th>
                                    <th scope="col">Enviar correo</th>
                                    <th scope="col">No Aplica</th>
                                    <th scope="col">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    estudiantes.map(
                                        estudiante => 
                                        <tr key = {estudiante.id}>
                                             <td> {estudiante.cedula} </td>   
                                             <td> {estudiante.apellido}</td>
                                             <td> {estudiante.nombre}</td>
                                             <td> {estudiante.email}</td>
                                             <td> {estudiante.institucion.nombre}</td>
                                             <td> {estudiante.estado}</td>
                                             <td>
                                                 <button onClick = {()=>{this.selectEstudiante(estudiante); this.handleModalVer()}} className="btn btn-sm btn-info"><FontAwesomeIcon icon="eye"/></button>
                                            </td>
                                            <td>
                                                 <button onClick = {()=>{this.getDocumentoEstudiante(estudiante.id); this.handleModalDocumentos()}} className="btn btn-sm btn-primary" >
                                                    <FontAwesomeIcon icon="file-download"/>
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick = {()=>{this.selectEstudiante(estudiante); this.handleModalAprobar()}} className="btn btn-sm btn-success" disabled={estudiante.estado!=="SOLICITADO"}>
                                                    <FontAwesomeIcon icon="check"/> Aprobar
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick = {()=>{this.selectEstudiante(estudiante); this.handleModalDevolucion()}} className="btn btn-sm btn-dark" disabled={estudiante.estado!=="SOLICITADO"}>
                                                    <FontAwesomeIcon icon="undo"/> Devolver
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick = {()=>{this.selectEstudiante(estudiante); this.handleModalCorreo()}} className="btn btn-sm btn-warning" disabled={estudiante.estado!=="APROBADO"}>
                                                    <FontAwesomeIcon icon="envelope"/> Correo
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick = {()=>{this.selectEstudiante(estudiante); this.handleModalNoAplica()}} className="btn btn-sm btn-danger" disabled={estudiante.estado!=="SOLICITADO" && estudiante.estado!=="DEVUELTO"}>
                                                    <FontAwesomeIcon icon="ban"/>
                                                </button>
                                            </td>
                                            <td>
                                            <DropdownButton id="dropdown-basic-button" title="Acción" size="sm" variant="outline-dark">
                                                <Dropdown.Item href={this.getActaUrl(estudiante.id)} target="_blank" disabled={estudiante.estado!=="FINALIZADO"}>Imprimir Acta</Dropdown.Item>
                                                <Dropdown.Item href={this.getActaMayorUrl(estudiante.id)} target="_blank" disabled={estudiante.estado!=="FINALIZADO"}>Imprimir Acta Mayor de Edad</Dropdown.Item>
                                            </DropdownButton>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                 </div>
                 )}

                 {/*MODAL APROBAR*/}
                 <Modal show={modalAprobar} onHide={this.handleModalAprobar} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Aprobar solicitud</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Desea aprobar la solicitud del estudiante <strong>{currentEstudiante && (currentEstudiante.nombre+" "+currentEstudiante.apellido)}</strong></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{this.handleAprobar()}}>
                        Aceptar
                      </Button>
                      <Button variant="secondary" onClick={this.handleModalAprobar}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/*MODAL VER*/}
                  <Modal show={modalVer} onHide={this.handleModalVer} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Datos del Estudiante</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentEstudiante && (
                            <>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="primary mx-2" onClick={()=>{this.handleEditar(currentEstudiante)}}>
                                    <FontAwesomeIcon icon={btnIconEditSave}/> {btnTitleEditSave}
                                </Button>
                                <a href = {FichaURL} className="btn btn-dark" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon="print"/> Ficha
                                </a>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="cedula"> Cédula: </label>
                                <input readOnly placeholder="Cédula de identidad" name="cedula" className="form-control" 
                                    value={currentEstudiante.cedula}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="nombre"> Nombres: </label>
                                <input readOnly={isDisabled} placeholder="Nombres" name="nombre" className="form-control" 
                                    value={currentEstudiante.nombre} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="apellido"> Apellidos: </label>
                                <input readOnly={isDisabled} placeholder="Apellidos" name="apellido" className="form-control" 
                                    value={currentEstudiante.apellido} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                            <label htmlFor="email" > Email: </label>
                                <div className="input-group">
                                        <input readOnly placeholder="Correo electrónico" name="email" className="form-control" 
                                            value={currentEstudiante.email}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button" onClick={this.handleModalCambiarEmail}>
                                            <FontAwesomeIcon icon="pen"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="genero"> Género: </label>
                                <div className="form-check">
                                    <input readOnly className="form-check-input" type="radio" name="genero" id="Masculino" value="Masculino" checked={currentEstudiante.genero === "Masculino"} />
                                    <label className="form-check-label" htmlFor="Masculino">
                                        Masculino
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input readOnly className="form-check-input" type="radio" name="genero" id="Femenino" value="Femenino" checked={currentEstudiante.genero === "Femenino"}/>
                                    <label className="form-check-label" htmlFor="Femenino">
                                        Femenino
                                    </label>
                                </div>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="telefono"> Teléfono: </label>
                                <input readOnly={isDisabled} placeholder="Teléfono" name="telefono" className="form-control" 
                                    value={currentEstudiante.telefono} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="celular"> Celular: </label>
                                <input readOnly={isDisabled} placeholder="Celular" name="celular" className="form-control" 
                                    value={currentEstudiante.celular} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="direccion"> Dirección: </label>
                                <input readOnly={isDisabled} placeholder="Dirección" name="direccion" className="form-control" 
                                    value={currentEstudiante.direccion} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="cedulaRep"> Cédula Representante: </label>
                                <input readOnly={isDisabled} placeholder="Cédula del Representante" name="cedulaRep" className="form-control" 
                                    value={currentEstudiante.cedulaRep} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label htmlFor="nombreRep"> Nombre Representante: </label>
                                <input readOnly={isDisabled} placeholder="Nombre del Representante" name="nombreRep" className="form-control" 
                                    value={currentEstudiante.nombreRep} onChange={this.handleInputChange}/>
                            </div>
                            <div className = "form-group">
                                <label> Institución: </label>
                                <input readOnly placeholder="Institución" name="institucion" className="form-control" 
                                    value={currentEstudiante.institucion.nombre}/>
                            </div>
                            <div className = "form-group">
                                <label> Jornada: </label>
                                <input readOnly placeholder="Jornada" name="jornada" className="form-control" 
                                    value={currentEstudiante.jornada}/>
                            </div>
                            </>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleModalVer}>
                        Aceptar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/*ENVIAR CORREO*/}
                  <Modal show={modalCorreo} onHide={this.handleModalCorreo} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Enviar correo de aprobación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se enviará un correo de aprobación de la solicitud y certificado a la siguiente dirección: <strong>{currentEstudiante && (currentEstudiante.email)}</strong></p>
                        <div className = "form-group">
                            <label htmlFor="mensajeCorreo"> Mensaje: </label>
                            <textarea placeholder="Ingrese un mensaje adicional..." name="mensajeCorreo" className="form-control" 
                                rows="8" value={mensajeCorreo} onChange={this.handleMensajeCorreoChange}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{this.handleEnviarCorreo(currentEstudiante,mensajeCorreo)}}>
                        Aceptar
                      </Button>
                      <Button variant="secondary" onClick={this.handleModalCorreo}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  
                  {/*DESCARGAR DOCUMENTOS*/}
                  <Modal show={modalDocumentos} onHide={this.handleModalDocumentos} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Descargar Documentos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{fileInfo?(
                        <a href={fileInfo.url}>{fileInfo.name}</a>
                    ):(
                        <p>No se encontraron archivos</p>
                    )
                        }</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleModalDocumentos}>
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  
                  {/*MODAL DEVOLUCION*/}
                  <Modal show={modalDevolucion} onHide={this.handleModalDevolucion} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Devolver solicitud</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se enviará un correo indicando la devolución de la solicitud a la siguiente dirección: <strong>{currentEstudiante && (currentEstudiante.email)}</strong></p>
                        <div className = "form-group">
                            <label htmlFor="mensajeDevolucion"> Mensaje: </label>
                            <textarea placeholder="Ingrese el motivo de la devolución..." name="mensajeDevolucion" className="form-control" 
                                rows="8" value={mensajeDevolucion} onChange={this.handleMensajeDevolucionChange}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{this.handleDevolver(currentEstudiante,mensajeDevolucion)}}>
                        Aceptar
                      </Button>
                      <Button variant="secondary" onClick={this.handleModalDevolucion}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/*MODAL NO APLICA*/}
                  <Modal show={modalNoAplica} onHide={this.handleModalNoAplica} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>La solicitud no aplica</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className = "form-group">
                            <label htmlFor="mensajeNoAplica"> Ingrese la razón o motivo:</label>
                            <textarea placeholder="Razón/Motivo..." name="mensajeNoAplica" className="form-control" 
                                rows="4" value={mensajeNoAplica} onChange={this.handleMensajeNoAplicaChange}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={()=>{this.handleNoAplica(currentEstudiante,mensajeNoAplica)}}>
                        Aceptar
                      </Button>
                      <Button variant="secondary" onClick={this.handleModalNoAplica}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/*MODAL CAMBIAR CORREO*/}
                  <Modal show={modalCambiarEmail} onHide={this.handleModalCambiarEmail} size="lg" centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Cambio de Correo Electrónico</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className = "form-group">
                            <label htmlFor="mensajeNoAplica"> El nuevo correo electrónico debe ser único y no debe encontrarse registrado con otro usuario:</label>
                            <input type="email" placeholder="Correo electrónico" name="nuevoEmail" className="form-control" 
                                            value={nuevoEmail} onChange={this.handleNuevoEmailChange}/>
                        </div>
                        {message && (
                            <div className="form-group pt-2">
                                <div
                                className={
                                    successful
                                    ? "alert alert-success"
                                    : "alert alert-danger"
                                }
                                role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={()=>{this.handleUpdateEmailEstudiante(currentEstudiante.id,nuevoEmail)}}>
                        Aceptar
                      </Button>
                      <Button variant="secondary" onClick={this.handleModalCambiarEmail}>
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Modal>
            </div>         
        )
    }
}

export default EstudianteList