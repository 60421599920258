import React, { Component } from 'react'

class FooterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }
    }

    render() {
        return (
                <footer className = "footer py-4 bg-primary">
                    <div className = "container text-center">
                        <span className="text-white">Todos los derechos reservados G.A.D. Ilustre Municipalidad del Cantón Daule - {new Date().getFullYear()}</span>
                    </div>
                </footer>
        )
    }
}

export default FooterComponent