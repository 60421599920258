import axios from "axios";

export default axios.create({
  baseURL: "https://bachillerdigital.daule.gob.ec/api/",
  //baseURL: "http://localhost:8080/api/",
  //baseURL: "https://localhost:8443/api/",
  headers: {
    "Content-type": "application/json"
  }
});

//EstudianteList, TabletEntrega