import http from "../http-common";
import authHeader from "./auth-header";

class ActaService{

    getAll() {
        return http.get("/acta", { headers: authHeader() });
    }
    
    getById(id) {
        return http.get(`/acta/${id}`, { headers: authHeader() });
    }

    saveActa(tablet, estudiante, usuarioCreacion) {
        return http.post("/acta", {tablet, estudiante, usuarioCreacion}, { headers: authHeader() });
    }
}
export default new ActaService();