import React, { Component } from "react";
import AuthService from "../services/AuthService";
import EstudianteService from "../services/EstudianteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: undefined,
      nombre:'',
      cedula:'',
      email:'',
      isAdmin: false,
      currentEstudiante:undefined
    };
  }

  componentDidMount() {
    //Obtener info del usuario actual
    const user = AuthService.getCurrentUser();
    if (user) {
        this.setState({
            currentUser: user,
            nombre: user.nombre+" "+user.apellido,
            cedula: user.username,
            email: user.email,
            isAdmin: user.roles.includes("ROLE_ADMIN"),
        });
    }

    if(!this.state.isAdmin){
      //Obtener Estudiante
      EstudianteService.getByUsername(user.username)
      .then(response => {
          console.log(response.status);
          this.setState({
              currentEstudiante: response.data
          });
      })
      .catch(e => {
          console.log(e);    
      });
    }
  }

  render() {
    const {
      currentUser, nombre, cedula, email, isAdmin,currentEstudiante
    } = this.state; 
    return (
      <div className="page-bg">
        <div className="container">
          <div className="jumbotron">
            <h3>¡Bienvenido/a, {nombre}!</h3>
            <p className="lead">{cedula}</p>
            <p className="lead text-muted"><FontAwesomeIcon icon="envelope"/> : {email}</p>
            <hr className="my-4"/>
            {currentEstudiante &&(
              <div>
                <h5>Estado</h5>
                <p>{currentEstudiante.estado}</p>
              </div>)}          
          </div>
        </div>
      </div>
    );
  }
}