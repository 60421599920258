import http from "../http-common";
import authHeader from "./auth-header";


class FileUploadService {
  upload(file, onUploadProgress, estudianteId) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("estudianteId", estudianteId);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  /*UPLOAD EXCEL TABLET*/
  uploadTablet(file, tabletTipoId) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("tabletTipoId", tabletTipoId);

    return http.post("/tablet/upload", formData, {
      headers: authHeader()
    });
  }


  getFiles() {
    return http.get("/files");
  }

  /*getFileById(id) {
    return http.get(`/files/${id}`);
  }*/

  getFileByFilename(filename) {
    return http.get(`/files/${filename}`);
  }

  getFileByEstudiante(estudianteId) {
    return http.get("/files/info",{params: {estudianteId:estudianteId}});
  }
}

export default new FileUploadService();