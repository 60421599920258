import http from "../http-common";
import authHeader from "./auth-header";

class TabletService{

    getAllTabletTipo() {
        return http.get("/tablettipo", { headers: authHeader() });
    }

    getTabletTipoById(id) {
        return http.get(`/tablettipo/${id}`, { headers: authHeader() });
    }

    getAll() {
        return http.get("/tablet", { headers: authHeader() });
    }
    
    getById(id) {
        return http.get(`/tablet/${id}`, { headers: authHeader() });
    }

    saveTablet(tablet) {
        return http.post("/tablet", tablet, { headers: authHeader() });
    }

    updateTablet(id, tablet) {
        return http.put(`/tablet/${id}`, tablet, { headers: authHeader() });
    }

    getTabletsDisponibles(tabletTipo){
        return http.get("/tablet/disponibles", { headers: authHeader() ,params: {tabletTipo:tabletTipo}});
    }
}
export default new TabletService();