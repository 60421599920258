import React, { Component } from "react";
import AuthService from "../../services/AuthService";
import EstudianteService from "../../services/EstudianteService";
import FileUploadService from "../../services/FileUploadService";
import { Modal, Button } from "react-bootstrap";

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);

    this.state = {
      currentUser: null,
      isAdmin: false,
      currentEstudiante: undefined,
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      successful: false,

      messageSolicitud:"",

      fileInfos: undefined,
      modalSolicitar: false
    };
  }

  componentDidMount() {
    //Obtener info del usuario actual
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        isAdmin: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EstudianteService.getByUsername(user.username)
    .then(response => {
      this.setState({
        currentEstudiante: response.data
      });
      console.log(response.data);
      FileUploadService.getFileByEstudiante(this.state.currentEstudiante.id)
      .then((response) => {
        this.setState({
          fileInfos: response.data,
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({
          fileInfos: undefined
        });
      });
    })
    .catch(e => {
      console.log(e);
      this.setState({
        fileInfos: undefined
      });
    });
    
  }

  handleModalSolicitar = () =>{
    this.setState({modalSolicitar: !this.state.modalSolicitar});
  }

  handleSolicitar = () =>{
    this.updateEstado(this.state.currentEstudiante,"SOLICITADO");
    this.handleModalSolicitar();
  }

  updateEstado(estudiante, estado){
    let updateEstudiante = {
        ...estudiante,
        estado: estado
    }
    
    EstudianteService.updateEstudiante(estudiante.id,updateEstudiante)
    .then(
        response => {
            console.log(response.data);
            this.setState(prevState => ({
              currentEstudiante:{
                ...prevState.currentEstudiante,
                estado: estado
              },
              messageSolicitud:"¡La solicitud se ha enviado exitosamente!"
            }));
        },
        error => {
            const resMessage =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            console.log(resMessage);
        }
        );
  }

  selectFile(event) {
    var myFile = event.target.files[0];
    if(myFile){
      if (!myFile.name.endsWith('.pdf'))
      {
        console.log(myFile);
        this.setState({
          message: "Por favor seleccione un archivo PDF válido",
          succesful:false
        });
      }
      else{
        this.setState({
          selectedFiles: event.target.files,
        });
      }
    }

  }

  upload() {
    let currentFile = this.state.selectedFiles[0];
    let estudianteId = this.state.currentEstudiante.id;

    this.setState({
      progress: 0,
      currentFile: currentFile,
    });

    FileUploadService.upload(currentFile, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    },estudianteId)
      .then((response) => {
        this.setState({
          message: response.data.message,
          succesful:true
        });
        return FileUploadService.getFileByEstudiante(estudianteId);
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch(() => {
          this.setState({
            progress: 0,
            message: "No se pudo subir el archivo",
            currentFile: undefined,
            succesful:false
          });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }

  render() {
    const {
      currentEstudiante,
      selectedFiles,
      currentFile,
      progress,
      message,
      messageSolicitud,
      fileInfos,
      modalSolicitar
    } = this.state;

    return (
      <div className="page-bg">
        <div className="container">
          <div className = "row">
            <div className="card col-md-10 offset-md-1 offset-md-1">
              <h3 className=" card-header bg-light text-center">Adjuntar archivo</h3>
              <div className="card-body">
                <div className="alert alert-info text-justify" role="alert">
                  <strong>Nota:</strong> Debe adjuntar el acta de grado notarizada, copia de cédula y certificado de votación del estudiante y representante legal, además de una copia de planilla de servicios básicos, todo en un único archivo PDF (Tamaño máximo de 5MB).
                </div>
                {currentEstudiante?(
                  <div>
                  {currentFile && (
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                      >
                        {progress}%
                      </div>
                    </div>
                  )}

                  <div className="input-group">
                      <input type="file" className="form-control p-1" id="inputFile" aria-describedby="inputSubirArchivo"  accept="application/pdf" onChange={this.selectFile} />
                    <div className="input-group-append">
                      <button className="btn btn-success" 
                        type="button" id="inputSubirArchivo"
                        disabled={!selectedFiles || (currentEstudiante.estado!=="REGISTRADO" && currentEstudiante.estado!=="DEVUELTO")}
                        onClick={this.upload}
                      >
                        Subir
                      </button>
                    </div>
                  </div>
                
                  <div className="alert alert-light" role="alert">
                    {message}
                  </div>

                  <div className="card">
                    <div className="card-header">Archivos subidos</div>
                    <ul className="list-group list-group-flush">
                      {fileInfos &&
                        (
                          <li className="list-group-item" key="1">
                            <a href={fileInfos.url}>{fileInfos.name}</a>
                          </li>
                        )}
                    </ul>
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary" onClick={this.handleModalSolicitar}
                    disabled={!fileInfos || (currentEstudiante.estado!=="REGISTRADO" && currentEstudiante.estado!=="DEVUELTO")} >
                        Enviar Solicitud
                    </button>
                    {messageSolicitud && (
                      <div className="mt-1 alert alert-success" role="alert">
                        {messageSolicitud}
                      </div>
                    )}
                  </div>
                </div>
                ):(
                  <div>
                    <div className="alert alert-danger" role="alert">
                    <strong>Atención:</strong> Debe registrarse como estudiante previamente
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal show={modalSolicitar} onHide={this.handleModalSolicitar} centered>
            <Modal.Header closeButton>
              <Modal.Title>Enviar solicitud</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Desea enviar su solicitud de estudiante? Una vez enviado no podrá realizar ningún cambio</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick= {()=>{this.handleSolicitar()}}>
                Aceptar
              </Button>
              <Button variant="secondary" onClick={this.handleModalSolicitar}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
          
        </div>
      </div>
    );
  }
}