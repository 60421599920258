import React, { Component } from "react";
import Select from 'react-select';
import EstudianteService from "../../services/EstudianteService";
import TabletService from "../../services/TabletService";
import AuthService from "../../services/AuthService";
import ActaService from "../../services/ActaService";

export default class TabletEntrega extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
          currentUser: undefined,
              
          tipoTabletId: '',
          tabletActual: undefined,
          serie: '',
          
          selectTipoTablet: [],
          selectTablet: [],
          selectEstudiantes: [],
          estudianteId: '',
          estudianteActual: undefined,

          message: '',
          successful: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTipoTabletChange = this.handleTipoTabletChange.bind(this);
        this.handleTabletChange = this.handleTabletChange.bind(this);
        this.handleEstudianteChange = this.handleEstudianteChange.bind(this);
        this.handleSaveActa = this.handleSaveActa.bind(this);
        this.generarActa = this.generarActa.bind(this);

    }

    handleInputChange(event) {
        console.log(event)

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        }); 
    }

    //OBTENER TABLET
    getTablet(id) {
        TabletService.getById(id)
          .then(response => {
            this.setState({
              tabletActual: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }

    handleTipoTabletChange(event){
        const index = event.value;
        this.setState({
          tipoTabletId:index,
          serie:'',
          tabletActual:undefined
        });
        this.getTabletOptions(index);
    }

    handleTabletChange(event){
      const index = event.value;
      this.setState({
        serie:index,
      });
      this.getTablet(index);
    }

    getEstudiante(id) {
        EstudianteService.getById(id)
          .then(response => {
            this.setState({
              estudianteActual: response.data
            });
          })
          .catch(e => {
            console.log(e);
          });
    }

    handleEstudianteChange(event){
        const index = event.value;
        this.setState({estudianteId:event.value});
        this.getEstudiante(index);
    }  

    async getTabletTipoOptions(){
        const res = await TabletService.getAllTabletTipo();
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : 'MARCA: '+d.marca+' - MODELO: '+d.modelo
        }))
    
        this.setState({selectTipoTablet: options})
    }

    async getTabletOptions(tabletTipo){
      const res = await TabletService.getTabletsDisponibles(tabletTipo);
      const data = res.data
  
      const options = data.map(d => ({
        "value" : d.id,
        "label" : d.serie
      }))
  
      this.setState({selectTablet: options})
  }

    async getEstudiantes(){
        const res = await EstudianteService.getAllByEstadoIn(['APROBADO']);
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.nombre+' '+d.apellido+' / '+d.cedula
        }))
    
        this.setState({selectEstudiantes: options})
    
    }

    handleSaveActa(e){
        e.preventDefault();

        const {
          tabletActual, estudianteActual, currentUser, successful
        } = this.state;

        if(tabletActual && estudianteActual && currentUser){
          if(successful){
            //LIMPIAR
            this.getTabletTipoOptions();
            this.getEstudiantes();
  
            this.setState({
              tipoTabletId: '',
              selectTablet: [],
              tabletActual: undefined,
              serie: '',
              estudianteId: '',
              estudianteActual: undefined,
              message: '',
              successful: false
            });
  
          }else{         
            ActaService.saveActa(tabletActual, estudianteActual, currentUser.id)
            .then(
              response => {
                this.setState({
                  message: response.data.message,
                  successful: true
                });
              },
              error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
      
                this.setState({
                  successful: false,
                  message: resMessage
                });
              }
            );
          } 

        }else{
          this.setState({
            successful: false,
            message: "Ingrese todos los campos"
          });
        }


    }

    generarActa(){
         EstudianteService.generarActaPdf(this.state.estudianteId);
    }

    componentDidMount() {  
        //Obtener info del usuario actual
        const user = AuthService.getCurrentUser();
        if (user) {
          /*UserService.getById(user.id)
          .then(response => {
            this.setState({
              currentUser: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });*/
          this.setState({
            currentUser: user
          });
          console.log(user);
        }

        this.getTabletTipoOptions();
        this.getEstudiantes();
    }

    render() {
        const {
            tipoTabletId,serie,selectTipoTablet,selectTablet,selectEstudiantes,estudianteId,estudianteActual,message,successful
        } = this.state;

        const btnTitleSaveUpdate = successful ? 'Nuevo' : 'Guardar';
        const  ActaURL= successful ? ("https://bachillerdigital.daule.gob.ec/api/acta/pdf?id="+estudianteId) : '#';
        const  ActaMayorURL= successful ? ("https://bachillerdigital.daule.gob.ec/api/acta/mayor?id="+estudianteId) : '#';
    
        return (
          <div className="row">
              <div className="container">
                  <div className = "row">
                      <div className="card col-md-10 offset-md-1 offset-md-1">
                          <h3 className=" card-header bg-light text-center">Acta de Entrega de Tablet</h3>
                          <div className="card-body">
                              <form onSubmit={this.handleSaveActa}>                             

                                  <div className = "form-group">
                                      <label> Tablet: </label>
                                      <Select
                                          value={selectTipoTablet.filter(obj => obj.value === tipoTabletId)} // set selected value 
                                          options={selectTipoTablet} onChange={this.handleTipoTabletChange} />
                                  </div>

                                  <div className = "form-group">
                                      <label> Número de Serie: </label>
                                      <Select
                                          value={selectTablet.filter(obj => obj.value === serie)} // set selected value 
                                          options={selectTablet} onChange={this.handleTabletChange} />
                                  </div>

                                  <div className = "form-group">
                                      <label> Estudiante: </label>
                                      <Select
                                          value={selectEstudiantes.filter(obj => obj.value === estudianteId)} // set selected value 
                                          options={selectEstudiantes} onChange={this.handleEstudianteChange} />
                                  </div>
                                  
                                      {estudianteActual ? (
                                          <div className = "card">
                                              <h5 className=" card-header bg-white p-0">DATOS DEL ESTUDIANTE</h5>
                                              <div className="card-body">
                                                  <div className = "row">
                                                      <label className="col-sm-3 font-weight-bold">Cédula de Identidad:</label>
                                                      <span className="col-sm-9">{estudianteActual.cedula}</span>
                                                  </div>
                                                  <div className = "row">
                                                      <label className="col-sm-3 font-weight-bold">Nombre completo:</label>
                                                      <span className="col-sm-9">{estudianteActual.nombre+' '+estudianteActual.apellido}</span>
                                                  </div>
                                                  <div className = "row">
                                                      <label className="col-sm-3 font-weight-bold">Institución:</label>
                                                      <span className="col-sm-9">{estudianteActual.institucion.nombre}</span>
                                                  </div>
                                                  <div className = "row">
                                                      <label className="col-sm-3 font-weight-bold">Período Lectivo:</label>
                                                      <span className="col-sm-9">{estudianteActual.periodoLectivo.descripcion}</span>
                                                  </div>
                                                  <div className = "row">
                                                      <label className="col-sm-3 font-weight-bold">Estado:</label>
                                                      <span className="col-sm-9">{estudianteActual.estado}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          
                                      ) : (
                                          <div className="alert alert-warning" role="alert">
                                              Seleccione un estudiante
                                          </div>
                                      )}                                   
                                  <button  className="btn btn-primary" >{btnTitleSaveUpdate}</button>
                                  <a href = {ActaURL} className={successful? "btn btn-secondary":"btn btn-secondary disabled"}  target="_blank" rel="noreferrer" style={{marginLeft: "10px"}}>Generar Acta</a>
                                  <a href = {ActaMayorURL} className={successful? "btn btn-secondary":"btn btn-secondary disabled"}  target="_blank" rel="noreferrer" style={{marginLeft: "10px"}}>Generar Acta Mayor de edad</a>
                                  {message && (
                                      <div className="form-group pt-2">
                                          <div
                                          className={
                                              successful
                                              ? "alert alert-success"
                                              : "alert alert-danger"
                                          }
                                          role="alert"
                                          >
                                              {message}
                                          </div>
                                      </div>
                                  )}
                              </form>                            
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        );
      }


}