import http from "../http-common";

class AuthService {
  login(username, password) {
    return http.post(
        "/auth/signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, nombre, apellido, email, password) {
    return http.post("/auth/signup", {
      username,
      nombre,
      apellido,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  forgotPassword(email) {
    return http.post("/auth/forgot-password", null,{
      params: {email: email}
    });
  }

  resetPassword(token,password,passwordConfirm) {
    return http.post("/auth/reset-password", {
      token,
      password,
      passwordConfirm
    });
  }

  getByCedula(cedula) {
    return http.get(`/auth/validate/${cedula}`);
  }

}

export default new AuthService();