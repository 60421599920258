// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faHome, faEdit, faCheck, faEye, faEnvelope, faFileDownload, faUndo, faTimes, faShare, faPrint, faSearch, faBan, faSave, faPen, faFileExcel, faTable } from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faEdit,
  faCheck,
  faEye,
  faEnvelope,
  faFileDownload,
  faUndo,
  faTimes,
  faShare,
  faPrint,
  faSearch,
  faBan,
  faSave,
  faPen,
  faFileExcel,
  faTable
);