import http from "../http-common";
import authHeader from "./auth-header";

class EstudianteService {
  /*getAll() {
    return http.get("/estudiante", { headers: authHeader() });
  }*/

  getAllFilter(params) {
    return http.get("/estudiante/filter", { headers: authHeader(), params: params });
  }

  getById(id) {
    return http.get(`/estudiante/${id}`, { headers: authHeader() });
  }

  saveEstudiante(estudiante) {
    return http.post("/estudiante", estudiante,{ headers: authHeader() });
  }

  updateEstudiante(id, estudiante) {
    return http.put(`/estudiante/${id}`, estudiante, {headers: authHeader() });
  }

  updateEmailEstudiante(id, email) {
    return http.put(`/estudiante/email/${id}`, email, { headers:  authHeader() });
  }

  deleteUser(id) {
    return http.delete(`/estudiante/${id}`, { headers: authHeader() });
  }

  getAllByEstadoIn(estados) {
    var myParams = new URLSearchParams(estados.map(s=>['estado',s]))

    return http.get("/estudiante/estado", { headers: authHeader() ,params: myParams});
  }

  generarActaPdf(estudianteId) {
    return http.get("/estudiante/acta/pdf", { headers: authHeader() ,params: {id:estudianteId}});
  }

  getByCedula(cedula) {
    return http.get(`/estudiante/cedula/${cedula}`, { headers: authHeader() });
  }

  getByUsername(username) {
    return http.get(`/estudiante/user/${username}`, { headers: authHeader() });
  }

  getInstituciones(){
    return http.get("/institucion", { headers: authHeader() });
  }
  
  exportToExcel(params) {
    return http.get(
      "/estudiante/export/excel",
      { responseType: 'blob',
        headers: authHeader(),
        params: params 
      });
  }

}

export default new EstudianteService();