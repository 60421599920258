import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/AuthService";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        La contraseña debe tener entre 6 a 40 caracteres.
      </div>
    );
  }
};

const vconfirm = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
  if (value !== components['password'][0].value) { // components['password'][0].value !== components['confirm'][0].value
  // 'confirm' - name of input
  // components['confirm'] - array of same-name components because of checkboxes and radios  
  return (
      <div className="alert alert-danger" role="alert">
        Las contraseñas no coinciden.
      </div>
    );
  }
};


export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirm = this.onChangeConfirm.bind(this);

    this.state = {
      token: this.props.match.params.token,
      password: "",
      confirm: "",
      successful: false,
      message: ""
    };
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeConfirm(e) {
    this.setState({
      confirm: e.target.value
    });
  }

  handleResetPassword(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.resetPassword(
        this.state.token,
        this.state.password,
        this.state.confirm
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="row page-bg">
        <div className="col-md-12">
          <div className="card card-container">
          <h3 className="text-center">Restablecer Contraseña</h3>

            <Form
              onSubmit={this.handleResetPassword}
              ref={c => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>

                  <div className="form-group">
                    <label htmlFor="password">Contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Confirmar contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="confirm"
                      value={this.state.confirm}
                      onChange={this.onChangeConfirm}
                      validations={[required,vconfirm]}
                    />
                  </div>

                  <div className="form-group">
                    <button className="btn btn-primary btn-block">Aceptar</button>
                  </div>
                </div>
              )}

              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
      
    );
  }
}