import React, { Component } from "react";
import Select from 'react-select';
import TabletService from "../../services/TabletService";
import AuthService from "../../services/AuthService";
import FileUploadService from "../../services/FileUploadService";

export default class TabletIngreso extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
          currentUser: undefined,
              
          tipoTabletId: '',
          
          selectTipoTablet: [],
          selectedFile: undefined,

          message: '',
          successful: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTipoTabletChange = this.handleTipoTabletChange.bind(this);

    }

    handleInputChange(event) {
        console.log(event)

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        }); 
    }

    handleFileChange = (event) => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    
    };

    upload = () => {
      FileUploadService.uploadTablet(this.state.selectedFile,this.state.tipoTabletId)
        .then((response) => {
          this.setState({
            message: response.data.message,
            successful:true
          });
        })
        .catch((error) => {
          const resMessage =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();

            this.setState({
              message: "No se pudo subir el archivo. "+resMessage,
              selectedFile: undefined,
              successful:false
            });
        });
    }

    handleTipoTabletChange(event){
        const index = event.value;
        this.setState({
          tipoTabletId:index
        });
    }

    async getTabletTipoOptions(){
        const res = await TabletService.getAllTabletTipo();
        const data = res.data
    
        const options = data.map(d => ({
          "value" : d.id,
          "label" : 'MARCA: '+d.marca+' - MODELO: '+d.modelo
        }));
    
        this.setState({selectTipoTablet: options});
    }

    componentDidMount() {  
        //Obtener info del usuario actual
        const user = AuthService.getCurrentUser();
        if (user) {
          this.setState({
            currentUser: user
          });
          console.log(user);
        }

        this.getTabletTipoOptions();
    }

    render() {
        const {
            tipoTabletId,selectTipoTablet,selectedFile,message,successful
        } = this.state;
     
        return (
          <div className="row">
              <div className="container">
                  <div className = "row">
                      <div className="card col-md-10 offset-md-1 offset-md-1">
                          <h3 className=" card-header bg-light text-center">Ingreso de Tablets</h3>
                          <div className="card-body">
                              <form onSubmit={this.handleSaveActa}>                             

                                  <div className = "form-group">
                                      <label> Tablet: </label>
                                      <Select
                                          value={selectTipoTablet.filter(obj => obj.value === tipoTabletId)} // set selected value 
                                          options={selectTipoTablet} onChange={this.handleTipoTabletChange} />
                                  </div>

                                  <div className = "form-group">
                                      <label> Número de Serie: </label>
                                      <div className="input-group">
                                        <input type="file" className="form-control p-1" id="inputFile" aria-describedby="inputSubirArchivo"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.handleFileChange} />
                                        <div className="input-group-append">
                                          <button className="btn btn-primary" 
                                            type="button" id="inputSubirArchivo"
                                            disabled={tipoTabletId==='' || !selectedFile}
                                            onClick={this.upload}
                                          >
                                            Subir
                                          </button>
                                        </div>
                                      </div>
                                  </div>
                                                                   
                                  {message && (
                                      <div className="form-group pt-2">
                                          <div
                                          className={
                                              successful
                                              ? "alert alert-success"
                                              : "alert alert-danger"
                                          }
                                          role="alert"
                                          >
                                              {message}
                                          </div>
                                      </div>
                                  )}
                              </form>                            
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        );
      }


}