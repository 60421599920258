import http from "../http-common";
import authHeader from "./auth-header";

class MailService {
  enviarCorreoEstudiante(estudiante, mensaje) {
    return http.post("/email", {estudiante,mensaje},{ headers: authHeader() });
  }

  enviarCorreoDevolucion(estudiante, mensaje) {
    return http.post("/email/devolucion", {estudiante,mensaje},{ headers: authHeader() });
  }

  enviarCorreoRendicion(nombre,cedula,sector,sugerencia) {
    return http.post("/email/rendicion", {nombre,cedula,sector,sugerencia});
  }
}

export default new MailService();